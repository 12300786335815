import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        title: 'Home',
      },
    },
    // Custom routes
    {
      path: '/buy-lottery',
      name: 'buy-lottery',
      component: () => import('@/views/customer/buy.vue'),
      meta: {
        title: 'Buy-Lottery',
      },
    },
    {
      path: '/order',
      name: 'order',
      component: () => import('@/views/customer/order.vue'),
      meta: {
        title: 'Order',
      },
    },
    {
      path: '/cart',
      name: 'cart',
      component: () => import('@/views/customer/cart.vue'),
      meta: {
        title: 'Cart',
      },
    },
    {
      path: '/lotto-list',
      name: 'lotto-list',
      component: () => import('@/views/customer/lotto-list.vue'),
      meta: {
        title: 'Cart',
      },
    },
    // Withdraw only
    {
      path: '/deposit-withdraw',
      name: 'withdraw',
      component: () => import('@/views/Depo-With.vue'),
    },

    // Agent routes
    {
      path: '/agent/buy-lottery',
      name: 'agent-buy-lottery',
      component: () => import('@/views/agent/buy.vue'),
      meta: {
        title: 'Agent-Buy-Lottery',
      },
    },
    {
      path: '/agent/order',
      name: 'order-agent',
      component: () => import('@/views/agent/order.vue'),
      meta: {
        title: 'Order',
      },
    },
    {
      path: '/agent/cart',
      name: 'cart-agent',
      component: () => import('@/views/agent/cart.vue'),
      meta: {
        title: 'Cart',
      },
    },
    {
      path: '/agent/lotto-list',
      name: 'lotto-list-agent',
      component: () => import('@/views/agent/lotto-list.vue'),
      meta: {
        title: 'Cart',
      },
    },
    {
      path: '/agent/lottery-set/:id',
      name: 'lottery-set-agent',
      component: () => import('@/views/agent/lottery-set.vue'),
      meta: {
        title: 'Cart',
      },
    },
    {
      path: '/agent/lotto-detail',
      name: 'lotto-detail',
      component: () => import('@/views/agent/lotto-detail.vue'),
      meta: {
        title: 'Cart',
      },
    },

    // Profile routes
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/profile.vue'),
      meta: {
        title: 'Profile',
      },
    },

    // History routes
    {
      path: '/history',
      name: 'history',
      component: () => import('@/views/history.vue'),
      meta: {
        title: 'History',
      },
    },

    // Login routes
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
